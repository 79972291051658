
video {
    object-fit:cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

h2 {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    padding-top: 100px;

}


.navbar-logo {
    font-family: 'Unica One', cursive;
    font-size: 40px;
}


.hero-container {
   /* background: url('./images/img-home.jpg') center center/cover no-repeat; */
height: 100vh;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
object-fit: contain;
}
